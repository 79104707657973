const get_option_001_001 = function () {
    return {
        legend: {},
        tooltip: {},
        dataset: {
            source: [
                ['type', '2021-07-01', '2021-07-02', '2021-07-03', '2021-07-04', '2021-07-05'],
                ['机票', 10, 20, 25, 30, 35],
                ['酒店', 1, 5, 15, 20, 25],
                ['火车票', 5, 10, 20, 40, 45]
            ],

        },
        xAxis: [
            {type: 'category'},
        ],
        yAxis: {},
        grid: {
            show: true,
            bottom: `10%`,
        },
        series: [
            // seriesLayoutBy=column，
            // series.count=5（数据列的数量，'2021-07-01', '2021-07-02', '2021-07-03', '2021-07-04', '2021-07-05'，共5个）
            // 即同一x下，有5个y
            // y轴为数据列
            // x轴为数据行
            {type: 'line'},
            {type: 'line'},
            {type: 'line'},
            {type: 'line'},
            {type: 'line'},
        ],
    };
};

const get_option_001_002 = function () {
    return {
        legend: {},
        tooltip: {},
        dataset: {
            source: [
                ['type', '2021-07-01', '2021-07-02', '2021-07-03', '2021-07-04', '2021-07-05'],
                ['机票', 10, 20, 25, 30, 35],
                ['酒店', 1, 5, 15, 20, 25],
                ['火车票', 5, 10, 20, 40, 45]
            ],

        },
        xAxis: [
            {type: 'category'},
        ],
        yAxis: {},
        grid: {
            show: true,
            bottom: `10%`,
        },
        series: [
            // seriesLayoutBy=row，
            // series.count=3（数据行的数量，'机票','酒店','火车票'，共3个）
            // 即同一x下，有3个y
            // y轴为数据行
            // x轴为数据列
            {type: 'line', seriesLayoutBy: 'row'},
            {type: 'line', seriesLayoutBy: 'row'},
            {type: 'line', seriesLayoutBy: 'row'},
        ],
    };
};

const get_option_002_001 = function () {
    return {
        legend: {},
        tooltip: {},
        dataset: {
            source: [
                [`date`, `机票`, `酒店`, `火车票`],
                [`2021-07-01`, 10, 1, 5],
                [`2021-07-02`, 20, 5, 10],
                [`2021-07-03`, 25, 15, 20],
                [`2021-07-04`, 30, 20, 40],
                [`2021-07-05`, 35, 25, 45],
            ],

        },
        xAxis: [
            {type: 'category'},
        ],
        yAxis: {},
        grid: {
            show: true,
            bottom: `10%`,
        },
        series: [
            // seriesLayoutBy=column，
            // series.count=3（数据列的数量，'机票', '酒店', '火车票'，共3个）
            // 即同一x下，有3个y
            // y轴为数据列
            // x轴为数据行
            {type: 'line'},
            {type: 'line'},
            {type: 'line'},
        ],
    };
};

const get_option_002_002 = function () {
    return {
        legend: {},
        tooltip: {},
        dataset: {
            source: [
                [`date`, `机票`, `酒店`, `火车票`],
                [`2021-07-01`, 10, 1, 5],
                [`2021-07-02`, 20, 5, 10],
                [`2021-07-03`, 25, 15, 20],
                [`2021-07-04`, 30, 20, 40],
                [`2021-07-05`, 35, 25, 45],
            ],

        },
        xAxis: [
            {type: 'category'},
        ],
        yAxis: {},
        grid: {
            show: true,
            bottom: `10%`,
        },
        series: [
            // seriesLayoutBy=row，
            // series.count=5（数据行的数量，'2021-07-01', '2021-07-02', '2021-07-03', '2021-07-04', '2021-07-05'，共5个）
            // 即同一x下，有5个y
            // y轴为数据行
            // x轴为数据列
            {type: 'line', seriesLayoutBy: 'row'},
            {type: 'line', seriesLayoutBy: 'row'},
            {type: 'line', seriesLayoutBy: 'row'},
            {type: 'line', seriesLayoutBy: 'row'},
            {type: 'line', seriesLayoutBy: 'row'},
        ],
    };
};

export default {
    data() {
        return {
            option_001_001: get_option_001_001(),
            option_001_002: get_option_001_002(),

            option_002_001: get_option_002_001(),
            option_002_002: get_option_002_002(),
        };
    },
    methods: {
        handleClick(...args) {
            console.log("click from echarts", ...args);
        },
        handleZrClick(...args) {
            console.log("click from zrender", ...args);
        },
    },
    mounted() {
    },
};
